import React, { useState, useEffect } from "react";

import PanelTemplate from "../../templates/Panel";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import Box from "../../components/Box";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import { renderError } from "../../helpers/errors";
import InputColor from "../../components/InputColor";
import { Loading } from "../../components/Loading";
import DotStatus from "../../components/DotStatus";
import { ModalDelete } from "../../components/ModalDelete";
import { toast } from "react-toastify";
import planosOptions from "../../seeds/planos";

const green = '#00b500';
const red = 'red';
const blue = '#7a7ae2';

const statusOptions = [
    { label: 'Ativo', value: 'ativo'},
    { label: 'Inativo', value: 'inativo'}
];

const projectsOptions = [
    { label: 'Padrão', value: 'prj_do5LEL0tMqUaFWnnGiJaIiVSEGby'},
    { label: 'Kopu', value: 'prj_fsUVwKTfBlXaQCo56i4vqZgVK81K'}
];

export const TenantEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [loadingDomain, setLoadingDomain] = useState(true);
    
    const [tenant, setTenant] = useState(null);
    const [nome, setNome] = useState('');
    const [status, setStatus] = useState('');
    const [plano, setPlano] = useState('');
    const [corPrimaria, setCorPrimaria] = useState('');
    const [corSecundaria, setCorSecundaria] = useState('');
    const [corTerciaria, setCorTerciaria] = useState('');
    const [domain, setDomain] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const [vercelProjectId, setVercelProjectId] = useState('');

    const [logoPngUrl, setLogoPngUrl] = useState(null);
    const [faviconUrl, setFaviconUrl] = useState(null);

    const [integracaoXbzUrl, setIntegracaoXbzUrl] = useState('');
    const [integracaoAsiaUrl, setIntegracaoAsiaUrl] = useState('');
    const [integracaoAsiaAppKey, setIntegracaoAsiaAppKey] = useState('');
    const [integracaoSpotUrl, setIntegracaoSpotUrl] = useState('');
    const [integracaoSpotAccessKey, setIntegracaoSpotAccessKey] = useState('');
    const [minQuantity, setMinQuantity] = useState('');
    const [baseSku, setBaseSku] = useState('');
    const [quantityUsers, setQuantityUsers] = useState('');

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [domainStatus, setDomainStatus] = useState(null);

    useEffect(() => {
        getTenant();
        getDomain();
    }, []);

    const getDomain = () => {
        setLoadingDomain(true);

        api.get(`/tenant/${id}/domain`).then(res => {
            setDomainStatus(res.data);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoadingDomain(false));
    }

    const getTenant = () => {
        setLoading(true);

        api.get(`/tenant/${id}`).then(res => {
            let resource = res.data.resource;

            setTenant(resource);
            setNome(resource.nome);
            setStatus(resource.status);
            setPlano(resource.plano);
            setCorPrimaria(resource.cor_primaria);
            setCorSecundaria(resource.cor_secundaria);
            setCorTerciaria(resource.cor_terciaria);
            setDomain(resource.domain);
            setSubdomain(resource.subdomain);
            setVercelProjectId(resource.vercel_project_id);

            setIntegracaoXbzUrl(resource.integracao_xbz_url);
            setIntegracaoAsiaUrl(resource.integracao_asia_url);
            setIntegracaoAsiaAppKey(resource.integracao_asia_app_key);
            setIntegracaoSpotUrl(resource.integracao_spot_url);
            setIntegracaoSpotAccessKey(resource.integracao_spot_access_key);
            setMinQuantity(resource.min_quantity);
            setBaseSku(resource.base_sku ?? '');
            setQuantityUsers(resource.quantity_users);

            setLogoPngUrl(resource.logo_png ?? null);
            setFaviconUrl(resource.favicon ?? null);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updateTenant = () => {
        setLoading(true);

        let form = new FormData;

        let elLogo = document.querySelector('input[name="logo"]');
        let fileLogo = elLogo.files[0];

        if(fileLogo !== undefined){
            form.append('logo_png', fileLogo);
        }

        let elFavicon = document.querySelector('input[name="favicon"]');
        let fileFavicon = elFavicon.files[0];

        if(fileFavicon !== undefined){
            form.append('favicon', fileFavicon);
        }

        form.append('_method', 'PUT');
        form.append('nome', nome);
        form.append('status', status);
        form.append('plano', plano);
        form.append('cor_primaria', corPrimaria);
        form.append('cor_secundaria', corSecundaria);
        form.append('cor_terciaria', corTerciaria);
        form.append('domain', domain);
        form.append('subdomain', subdomain);
        form.append('vercel_project_id', vercelProjectId);
        form.append('integracao_xbz_url', integracaoXbzUrl);
        form.append('integracao_asia_url', integracaoAsiaUrl);
        form.append('integracao_asia_app_key', integracaoAsiaAppKey);
        form.append('integracao_spot_url', integracaoSpotUrl);
        form.append('integracao_spot_access_key', integracaoSpotAccessKey);
        form.append('min_quantity', minQuantity);
        form.append('quantity_users', quantityUsers);
        form.append('base_sku', baseSku);

        api.post(`/tenant/${id}`, form).then(() => {
            toast.success('Cliente atualizado com sucesso');
            getTenant();
            getDomain();
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const deleteTenant = () => {
        setLoading(true);

        api.delete(`/tenant/${id}`).then(res => {
            toast.success(res.data.message);
            navigate('/tenant/list');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const removeLogo = () => {
        setLogoPngUrl(null);
    }

    const removeFavicon = () => {
        setFaviconUrl(null);
    }

    const installSSL = e => {
        e.preventDefault();
        setLoadingDomain(true);

        api.post(`/tenant/${id}/ssl`).then(res => {
            toast.success(res.data.message);
            getDomain();
        }).catch(error => {
            renderError(error);
            setLoadingDomain(false);
        });
    }

    return (
        <>
            <ModalDelete
                show={confirmDelete}
                action={deleteTenant}
                loading={loading}
                close={() => setConfirmDelete(false)}
            />
            <PanelTemplate id="tenant-edit">
                <Box>
                    {!loading &&
                        <>
                            <div className="row">
                                <div className="col-3">
                                    <Input label={`Nome`} value={nome} change={setNome} />
                                </div>
                                <div className="col-3">
                                    <InputSelect label={`Plano`} value={plano} options={planosOptions} change={setPlano} />
                                </div>
                                <div className="col-3">
                                    <Input label={`Quantidade de usuários`} value={quantityUsers} change={setQuantityUsers} mask={`convertToInt`} />
                                </div>
                                <div className="col-3">
                                    <InputSelect label={`Status`} value={status} options={statusOptions} change={setStatus} />
                                </div>
                            </div>

                            <h2 className="section-title">Tema</h2>

                            <div className="row">
                                <div className="col-3">
                                    <InputSelect label={`Tema`} value={vercelProjectId} options={projectsOptions} change={setVercelProjectId} />
                                </div>
                                <div className="col-3">
                                    <InputColor label={`Cor primária`} value={corPrimaria} change={setCorPrimaria} />
                                </div>
                                <div className="col-3">
                                    <InputColor label={`Cor secundária`} value={corSecundaria} change={setCorSecundaria} />
                                </div>
                                <div className="col-3">
                                    <InputColor label={`Cor terciária`} value={corTerciaria} change={setCorTerciaria} />
                                </div>
                            </div>

                            <div className="row">
                                
                                <div className="col-6">
                                    <h2 className="section-title">Logo</h2>

                                    {logoPngUrl &&
                                        <div className="tenant-logo">
                                            <img src={`${logoPngUrl}?t=${new Date().getTime()}`} alt={nome} />
                                            <button onClick={removeLogo}>x</button>
                                        </div>
                                    }
                                    <input style={{ marginBottom: 15, display: logoPngUrl ? 'none' : 'block'}} accept="image/png" type="file" name="logo" />
                                    {!logoPngUrl &&
                                        <p style={{ fontSize: 12}}><b>Formato aceito: PNG. Tamanho ideal: 500px de largura</b></p>
                                    }
                                </div>

                                <div className="col-6">
                                    <h2 className="section-title">Favicon</h2>

                                    {faviconUrl &&
                                        <div className="tenant-favicon">
                                            <img src={`${faviconUrl}?t=${new Date().getTime()}`} alt={nome} />
                                            <button onClick={removeFavicon}>x</button>
                                        </div>
                                    }
                                    <input style={{ marginBottom: 15, display: faviconUrl ? 'none' : 'block'}} accept="image/png" type="file" name="favicon" />
                                    {!faviconUrl &&
                                        <p style={{ fontSize: 12}}><b>Formato aceito: PNG. Tamanho ideal: 32px por 32px</b></p>
                                    }
                                </div>
                            </div>

                            <h2 className="section-title">Detalhes dos domínios</h2>

                            <div className="row">
                                <div className="col-4">
                                    <Input label={`Domínio do site`} value={domain} change={setDomain} />
                                </div>
                                <div className="col-4">
                                    <Input label={`Domínio do site`} value={`www.${domain}`} disabled={true} />
                                </div>
                                <div className="col-4">
                                    <Input label={`Subdomínio do sistema`} value={subdomain} change={setSubdomain} />
                                    <div className="ssl-info">
                                        {!loadingDomain && domainStatus && !domainStatus.subdomain.ssl &&
                                            <a href="#" onClick={installSSL}>Instalar SSL</a>
                                        }
                                        {!loadingDomain && domainStatus && domainStatus.subdomain.ssl &&
                                            <span className="success">SSL instalado</span>
                                        }
                                        {loadingDomain &&
                                            <span>Consultando SSL...</span>
                                        }
                                    </div>
                                </div>

                                {/* {!loadingDomain && domainStatus && tenant.domain === domain &&
                                    <>
                                        <div className="col">
                                            <div className="domain-status">
                                                <p style={{ color: domainStatus.domain.status ? green : red}}>{domainStatus.domain.status ? 'Configuração validada' : 'Aguardando configuração'}</p>
                                                <p className="message">{domainStatus.domain.message}</p>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="domain-status">
                                                <p style={{ color: domainStatus.domain.ssl ? green : red}}>{domainStatus.domain.ssl ? 'SSL instalado' : 'Aguardando instalação'}</p>
                                            </div>
                                        </div>
                                    </>
                                } */}
                            </div>
                        
                            <h2 className="section-title">Detalhes das integrações</h2>

                            <div className="row">
                                <div className="col-4">
                                    <Input label={`XBZ URL`} value={integracaoXbzUrl} change={setIntegracaoXbzUrl} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <Input label={`ASIA Imports URL`} value={integracaoAsiaUrl} change={setIntegracaoAsiaUrl} />
                                </div>
                                <div className="col-4">
                                    <Input label={`ASIA Imports App Key`} value={integracaoAsiaAppKey} change={setIntegracaoAsiaAppKey} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <Input label={`SPOT URL`} value={integracaoSpotUrl} change={setIntegracaoSpotUrl} />
                                </div>
                                <div className="col-4">
                                    <Input label={`SPOT Access Key`} value={integracaoSpotAccessKey} change={setIntegracaoSpotAccessKey} />
                                </div>
                            </div>

                            <h2 className="section-title">Definições padrões</h2>

                            <div className="row">
                                <div className="col-4">
                                    <Input label={`Quantidade mínima padrão`} value={minQuantity} change={setMinQuantity} mask={`convertToInt`} />
                                </div>
                                <div className="col-4">
                                    <Input label={`SKU base`} value={baseSku} change={setBaseSku} />
                                </div>
                            </div>
                        </>
                    }
                    {loading &&
                        <Loading />
                    }
                </Box>
                <PageFooter spaceBetween={true}>
                    <Button
                        type={`primary`}
                        size={`small`}
                        svg={`save-white`}
                        text={`Salvar alterações`}
                        action={updateTenant}
                        loading={loading}
                    />
                    <Button
                        type={`secondary`}
                        size={`small`}
                        svg={`delete-small`}
                        text={`Excluir`}
                        action={() => setConfirmDelete(true)}
                        loading={loading}
                    />
                </PageFooter>
            </PanelTemplate>
        </>
    );
}

export default TenantEdit;