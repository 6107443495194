import UserList from '../pages/User/list';

import TenantEdit from "../pages/Tenant/edit";
import TenantList from "../pages/Tenant/list";
import TenantCreate from "../pages/Tenant/create";
import ProductList from '../pages/Product/list';

export const privateRoutes = [
    {
        path: "/tenant/list",
        element: <TenantList />,
        show: true,
        main: true
    },
    {
        path: "/tenant/edit/:id",
        element: <TenantEdit />,
        show: true,
        main: false
    },
    {
        path: "/tenant/create",
        element: <TenantCreate />,
        show: true,
        main: false
    },
    {
        path: "/user/list",
        element: <UserList />,
        show: true,
        main: true
    },
    {
        path: "/product/list",
        element: <ProductList />,
        show: true,
        main: true
    }
];

export const getInitialPath = () => {
    let path = null;

    for(let i = 0; i < privateRoutes.length; i++){
        console.log(privateRoutes, path = privateRoutes[i].path, privateRoutes[i].show && privateRoutes[i].main)
        if(privateRoutes[i].show && privateRoutes[i].main){
            path = privateRoutes[i].path;
            break;
        }
    }
    
    return path;
}

export default privateRoutes;