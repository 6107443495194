import React, { useEffect, useState } from "react";
import Select from "react-select";
import * as Masks from "../../helpers/masks";

import { createOptions } from '../../helpers/selects';

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '35px',
      height: '35px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      boxShadow: state.isFocused ? null : null,
      fontSize: '14px'
    }),
}

export const FilterSimple = ({params, filter}) => {
    const options = createOptions(params, 'name', 'key');

    const [option, setOption] = useState(options[0]);
    const [type, setType] = useState('');
    const [operation, setOperation] = useState('');
    const [mask, setMask] = useState(null);
    const [value, setValue] = useState('');

    useEffect(() => {
        let current = params.filter(param => param.key === option.value);
        setType(current[0].type);
        setOperation(current[0]?.operation ?? 'contain');
        setMask(current[0]?.mask ?? null);
    }, [option]);

    // useEffect(() => {
    //     if(value) filter(`?${option.value}[contain]=${value}`);
    // }, [value]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            filter(`${option.value}=${value}`);
            // filter(`filters[0]${option.value}[${operation}]=${value}`);
        }
    }

    const applyMask = value => {
        let maskedValue = value;
        if(mask){
            maskedValue = Masks[mask](value);
        }

        setValue(maskedValue);
    }

    return (
        <div className="filter-simple">
            {(type === 'text' || type === 'email') &&
                <input 
                    type={type} 
                    placeholder="Buscar" 
                    value={value} 
                    onChange={e => applyMask(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
            }
            <Select options={options} value={option} onChange={setOption} styles={customStyles} />
        </div>
    );
}

export default FilterSimple;